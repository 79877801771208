import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtediadema.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3510401',
  schema: '1',
  groupId: '1',
  analiseGeral: '1',
  analiseUsuarios: '1',
  analiseComunicados: '1',
  analiseProcurador: '1',
  analiseEventos: '1',
  analiseContribuintes: '4f24fefe-9eaa-484d-a92d-5d9c83730874'
};
